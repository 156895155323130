import React from "react";
import Layout from "../components/element/layout/Layout";
import SEO from "../components/element/SEO";
import LayoutMain from "../components/element/layout/LayoutMain";
import LayoutColumn from "../components/element/layout/LayoutColumn";
import LoginFirebase from "../components/auth/login/LoginFirebase";
import LoginHeader from "../components/auth/login/LoginHeader";
import LoginFooter from "../components/auth/login/LoginFooter";
import routes from "../components/navigation/routes";


const seo = {
  title: `${routes.login.caption} - Oberion`,
  description: "Generate, edit, and publish beautiful websites for your video game. It's free to join and easy to use. Publish your free website within 5 minutes.",
  canonical: routes.baseUrl + routes.login.to,
};

const Login = () => {
  const [termsAccepted, setTermsAccepted] = React.useState(false);

  return (
    <Layout>
      <SEO {...seo} />
      <LayoutMain>
        <LayoutColumn>

          <LoginHeader setTermsAccepted={setTermsAccepted} />
          <LoginFirebase termsAccepted={termsAccepted} />
          <LoginFooter />

        </LayoutColumn>
      </LayoutMain>
    </Layout>
  );
};
export default Login;