import React from "react";
import { Typography } from "@material-ui/core";
import styled from "styled-components";
import { ConciseUl } from "../../element/page-element/ConciseComponents";
import { InternalRoute } from "../../navigation/InternalRoute";
import routes from "../../navigation/routes";

const LoginNoticeDiv = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 28rem;
  text-align: left;
  margin: 0 auto;
`;
const OpenSourceDiv = styled.div`
  padding: 2rem 0;
  text-align: center;
`;

const code = ["Your own code inside your repositories is safe and remains invisible to us."];
const alwaysDelete = ["You can ", "completely remove and delete your data", " at any time."];
const neverSold = "Your personal data will never be sold or transferred to anyone.";
const privacyPolicyLink = ["GDPR compliant ", "privacy policy", " under German law."];
const txt = [
  "Open Source 😇",
  "Build a great game 💙",
];


const LoginFooter = () => {
  return (
    <>
      <OpenSourceDiv>
        <Typography variant="body1">{txt[0]}</Typography>
        <Typography variant="body1">{txt[1]}</Typography>
      </OpenSourceDiv>

      <LoginNoticeDiv>
        <Typography component="span">
          <ConciseUl>

            <li>{code[0]}</li>

            <li>
              {alwaysDelete[0]}
              {alwaysDelete[1]}
              {alwaysDelete[2]}
            </li>

            <li>{neverSold}</li>

            <li>
              {privacyPolicyLink[0]}
              <InternalRoute caption={privacyPolicyLink[1]} to={routes.privacy.to} lightTheme />
              {privacyPolicyLink[2]}
            </li>
          </ConciseUl>
        </Typography>
      </LoginNoticeDiv>
    </>
  );
};

export default LoginFooter;