import React from "react";
import iconOberion from "../../../res/branded-oberion/logo.OberionIO.min.svg";
import GitHubIcon from "@material-ui/icons/GitHub";
import { Typography } from "@material-ui/core";
import styled from "styled-components";
import { oo } from "../../element/theme";
import { lessOrEqualThan } from "../../element/mediaQuery";

const Header = styled.header`
  margin-bottom: 2rem;
  text-align: center;
`;
const LoginGraphic = styled.div`
  color: #000000;
  background-color: ${oo.white};
  box-shadow: ${oo.boxShadow};
  border-radius: 2.5rem;
  width: 100%;
  margin: ${oo.verticalSpacing}rem auto ${oo.verticalSpacing}rem auto;
  padding: 2rem 2rem;
    ${lessOrEqualThan.sm`
    padding: 2rem 0rem;
  `}; 
  display: flex;
  justify-content: center;
  align-items: center;
`;
const BrandLogo = styled.img`
  margin: 0 2rem;
  height: 6rem;
  width: auto;
  ${lessOrEqualThan.sm`
    margin: 0 1rem;
    height: 4rem;
  `};
`;
const BrandLogoGitHub = styled(GitHubIcon)`
  margin: 0 2rem;
  height: 6rem;
  width: auto;
  ${lessOrEqualThan.sm`
    margin: 0 1rem;
    height: 4rem;
  `};
`;

const txt = [
  "Welcome to Oberion",
  "Signing in with GitHub allows us",
  "to build a website and deliver it to you",
];

const ConnectSteamHeader = () => {
  return (
    <Header>
      <Typography variant="h2" component="h1">{txt[0]}</Typography>
      <LoginGraphic>
        <BrandLogo src={iconOberion} alt="Oberion Logo" />
        <Typography component="span" variant="h1">+</Typography>
        <BrandLogoGitHub alt="Steam Logo" />
      </LoginGraphic>
      <Typography variant="subtitle2">{txt[1]}</Typography>
      <Typography variant="subtitle2">{txt[2]}</Typography>

    </Header>
  );
};

export default ConnectSteamHeader;