import React, { useContext, useEffect } from "react";
import PropTypes from "prop-types";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import { AuthContext } from "../AuthContextProvider";
import Button from "@material-ui/core/Button";
import { navigate } from "gatsby";
import routes from "../../navigation/routes";
import { logError } from "../../element/error/sentry";
import ky from "ky";
import endpoint from "../../rest/endpoint";
import LoadingSpinner from "../../element/graphical/LoadingSpinner";
// import GitHubButton from "./GitHubButton";

const createNewUser = async (user) => {
  try {
    const idToken = await user.getIdToken();
    const requestOptions = { json: { idToken: idToken } };
    await ky.post(endpoint.user, requestOptions).json();

  } catch (error) {
    logError(error);
  }
};

/**
 * Handle the login flow.
 *
 * Why use useEffect and make the login flow more complicated?
 * Firebase SDK cannot initialize during Gatsby SSR. The detour with useEffect makes sure Firebase SDK is only loaded in the browser.
 */
const LoginFirebase = ({ termsAccepted }) => {
  const authContext = useContext(AuthContext);
  const firebase = authContext.getFirebase();

  const loadingSpinner = <LoadingSpinner />;
  const loginFailureUi = <Button variant="contained" disabled>Login failed</Button>; // Create proper [login failure feedback]
  const [loginUi, setLoginUi] = React.useState(loadingSpinner);
  let isLoginFailure = false;

  /**
   * IV. Redirect logged in users.
   */
  // if (authContext.isLoggedIn() && isNewUser) navigate(routes.user.welcome.to);
  if (authContext.isLoggedIn()) {
    if (authContext.getRedirectTo() === null) navigate(routes.user.sites.create.to);
    navigate(authContext.getRedirectTo());
  }


  const uiConfig = (auth) => {
    return {
      signInOptions: [auth.GithubAuthProvider.PROVIDER_ID],
      tosUrl: "https://oberion.io/terms/",
      privacyPolicyUrl: "https://oberion.io/privacy/",
      /**
       * @see https://github.com/firebase/firebaseui-web#available-callbacks
       */
      callbacks: {
        /* --- Check for new users --- */
        signInSuccessWithAuthResult: async (authService) => {
          if (authService && authService.user &&
            authService.additionalUserInfo && authService.additionalUserInfo.isNewUser) {
            // https://firebase.google.com/docs/reference/js/firebase.auth#usercredential
            authContext.setNewUser(true);
            await createNewUser(authService.user);
          }
          return false; // callback initiates redirect if return is true
        },

        /* --- Display proper error message and log error --- */
        signInFailure: (error, credential) => {
          logError(error, {
              path: "uiConfig -> callbacks -> signInFailure",
              credential: credential,
            },
          );
          isLoginFailure = true;
        },
      },
    };
  };

  /**
   * II. Load <StyledFirebaseUi> when user is not signed in.
   */
  const changeLoginUi = (loggedIn) => {
    if (loggedIn) {
      setLoginUi(loadingSpinner);
      // } else if (!termsAccepted) {
      //   console.log("aa");
      //   setLoginUi(
      //     <StyledFirebaseAuth uiConfig={null} firebaseAuth={null} />,
      //   );
    } else {
      setLoginUi(
        <StyledFirebaseAuth uiConfig={uiConfig(firebase.auth)} firebaseAuth={firebase.auth()} />,
      );
    }
  };

  /**
   * I. useEffect: Check whether user is signed in.
   */
  useEffect(() => {
    if (!firebase) return;
    changeLoginUi(authContext.isLoggedIn());
  }, [firebase, authContext.getUser()]);
  /**
   * III.  useEffect: Check whether login failed.
   */
  useEffect(() => {
    if (isLoginFailure) setLoginUi(loginFailureUi);
  }, [isLoginFailure]);

  return (
    <>
      {loginUi}
      {/*<GitHubButton />*/}
    </>
  );
};
LoginFirebase.propTypes = {
  termsAccepted: PropTypes.bool.isRequired,
};
export default LoginFirebase;
